<!--
 * @Author: yuwenwen
 * @Date: 2022-12-01 11:16:52
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-02-09 16:37:48
 * @FilePath: \nc-funeral-mobile\src\views\obituary\addContent.vue
-->
<template>
  <div class="reserve-transport">
    <van-form>
      <div class="title-box">
        <div class="title-text">请完整填写一下资料以便生成您的电子讣告</div>
      </div>
      <!-- 逝者头像 -->
      <div class="upload-avatar">
        <FileUpload
          :bizPath="'deadInfo/pic'"
          @res="uploadRes"
          :uploadIcon="uploadIcon"
        ></FileUpload>
        <div class="title">逝者头像</div>
      </div>
      <!-- 讣告标题 -->
      <div class="line-item">
        <div class="label-text">讣告标题</div>
        <van-field
          v-model="addForm.title"
          name="title"
          placeholder="例如：追思告别仪式"
        />
      </div>
      <div class="line-item">
        <div class="label-text">逝者名称</div>
        <van-field
          v-model="addForm.deadName"
          name="deadName"
          placeholder="例如：xxx同志"
        />
      </div>
      <div class="line-item">
        <div class="label-text">逝者年龄</div>
        <van-field
          v-model="addForm.deadAge"
          name="deadAge"
          placeholder="例如：80"
        />
      </div>
      <!-- 日期 -->
      <div class="line-item">
        <div class="label-text">去世日期</div>
        <van-field
          v-model="addForm.deadDay"
          name="deadDay"
          placeholder="请选择去世日期"
          is-link
          readonly
          @click="showDeathDate = true"
        />

        <van-popup v-model:show="showDeathDate" position="bottom">
          <van-date-picker
            v-model="deathDataDisPlay"
            title="请选择日期"
            type="date"
            @cancel="showDeathDate = false"
            @confirm="dateTimeConfirm"
            :max-date="new Date()"
          />
        </van-popup>
      </div>
      <!-- 去世时间 -->
      <div class="line-item">
        <div class="label-text">去世时间</div>
        <van-field
          v-model="addForm.deadTime"
          name="deadTime"
          placeholder="请选择去世时间"
          is-link
          readonly
          @click="showDeathTime = true"
        />
        <van-popup v-model:show="showDeathTime" position="bottom">
          <van-time-picker
            v-model="deathTimeDisplay"
            title="选择时间"
            @cancel="showDeathTime = false"
            @confirm="deathTimeConfirm"
          />
        </van-popup>
      </div>
      <!-- 仪式日期 -->
      <div class="line-item">
        <div class="label-text">仪式日期</div>
        <van-field
          v-model="addForm.ceremonyDay"
          name="ceremonyDay"
          placeholder="请选择仪式日期"
          is-link
          readonly
          @click="showCeleDate = true"
        />
        <van-popup v-model:show="showCeleDate" position="bottom">
          <van-date-picker
            v-model="celeDateDisplay"
            type="date"
            :min-date="new Date()"
            @cancel="showCeleDate = false"
            @confirm="celeDateConfirm"
          />
        </van-popup>
      </div>
      <!--  仪式时间-->
      <div class="line-item">
        <div class="label-text">仪式时间</div>
        <van-field
          v-model="addForm.ceremonyTime"
          name="ceremonyTime"
          placeholder="请选择仪式时间"
          is-link
          readonly
          @click="showCeleTime = true"
        />
        <van-popup v-model:show="showCeleTime" position="bottom">
          <van-time-picker
            v-model="celeTimeDisplay"
            title="选择时间"
            @cancel="showCeleTime = false"
            @confirm="celeTimeConfirm"
          />
        </van-popup>
      </div>
      <!-- 仪式地址 -->
      <div class="line-item" >
        <div class="label-text">仪式地址</div>
        <van-field
          v-model="addForm.ceremonyAddress"
          name="ceremonyAddress"
          placeholder="请选择地址"
          :readonly="true"
        />
      </div>
      <!-- 仪式地点 -->
      <div class="line-item">
        <div class="label-text">仪式地点</div>
        <van-field
          v-model="addForm.ceremonyPlace"
          name="ceremonyPlace"
          placeholder="例如：南昌市殡仪馆仙鹤厅"
        />
      </div>

      <!-- 联系电话 -->
      <div class="line-item">
        <div class="label-text">联系电话</div>
        <van-field
          v-model="addForm.contactNumber"
          name="contactNumber"
          placeholder="请输入家属联系电话"
        />
      </div>
      <!-- 联系电话 -->
      <div class="line-item">
        <div class="label-text">邀请人</div>
        <van-field
          v-model="addForm.inviter"
          name="inviter"
          placeholder="例如：全体家属"
        />
      </div>

      <!-- 提交按钮 -->
      <div class="btn-box">
        <div class="btn" @click="generateTemplate">生成预览</div>
      </div>
    </van-form>
  </div>

  <!-- 选择地址 -->
  <location
    v-if="isDisplayLocation"
    @locationSelected="locationSelected"
  ></location>
</template>
<script>
import FileUpload from "@/components/fileUpload.vue";
import { parseTime } from "@/utils/funeral.js";
import Location from "../common/location.vue";
export default {
  name: "AddContent",
  components: {
    FileUpload,
    Location,
  },
  data() {
    return {
      addForm: {
        ceremonyAddress:'江西省南昌市新建区松鹤路723号'
      },
      uploadIcon: require("../../assets/images/obituary/template1/default-avatar.png"),
      showDeathDate: false,
      deathDataDisPlay: [],
      showDeathTime: false,
      deathTimeDisplay: [],
      showCeleDate: false,
      celeDateDisplay: [],
      showCeleTime: false,
      celeTimeDisplay: [],
      template: {
        templateId: "",
        templatePath: "",
      },
      isDisplayLocation: false,
    };
  },
  beforeRouteLeave(to, from) {
    if (this.isDisplayLocation) {
      if (
        (to.path == "/obituary/template/elegant" ||
          to.path == "/obituary/template/refined" ||
          to.path == "/obituary/template/simple") &&
        from.path == "/obituary/add/content"
      ) {
        this.isDisplayLocation = false;
        this.$router.push({ path: "/obituary/add/content" });
      }
    }
  },
  created() {
    this.template = { ...this.$route.query };
    let currentDate = parseTime(new Date(), "{y}-{m}-{d}").split("-");
    this.deathDataDisPlay = currentDate;
    this.celeDateDisplay = currentDate;
    let currentTime = parseTime(new Date(), "{h}:{i}").split(":");
    this.deathTimeDisplay = currentTime;
    this.celeTimeDisplay = currentTime;
  },
  methods: {
    // 文件上传成功回调
    uploadRes(res) {
      this.addForm.deadImg = res.message;
    },
    // 生成模板
    async generateTemplate() {
      // let _this = this;
      this.$store.dispatch("setObituaryContent", {
        ...this.addForm,
        ...this.$route.query,
      });
      console.log(this.$route.query.templatePath);
      this.$router.push({
        path: this.$route.query.templatePath,
        query: { type: "preview", ...this.$route.query },
      });
    },
    // 逝者去世日期
    dateTimeConfirm(time) {
      console.log(time);
      let currentDate = time.selectedValues;
      this.deathDataDisPlay = currentDate;
      this.addForm.deadDay = currentDate
        .map((item) => {
          return item;
        })
        .join("-");
      this.showDeathDate = false;
    },
    // 去世时间
    deathTimeConfirm(time) {
      console.log(time);
      this.deathTimeDisplay = time.selectedValues;
      this.addForm.deadTime = time.selectedValues
        .map((item) => {
          return item;
        })
        .join(":");
      this.showDeathTime = false;
    },
    // 仪式日期
    celeDateConfirm(time) {
      this.celeDateDisplay = time.selectedValues;
      this.addForm.ceremonyDay = time.selectedValues
        .map((item) => {
          return item;
        })
        .join("-");
      this.showCeleDate = false;
    },
    // 仪式时间
    celeTimeConfirm(time) {
      this.celeTimeDisplay = time.selectedValues;
      this.addForm.ceremonyTime = time.selectedValues
        .map((item) => {
          return item;
        })
        .join(":");
      this.showCeleTime = false;
    },
    // 地址选择
    getLoaction() {
      this.isDisplayLocation = true;
    },
    locationSelected(item) {
      this.isDisplayLocation = false;
      this.addForm.ceremonyAddress = item.address;
      this.addForm.lng = item.point.lng;
      this.addForm.lat = item.point.lat;
    },
  },
};
</script>
<style lang="less" scoped>
.upload-avatar {
  height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  .title {
    margin-top: 10px;
  }
}
.title-box {
  height: 45px !important;
  .title-text {
    font-size: 14px !important;
  }
}
/deep/.van-field__control {
  color: rgba(0, 0, 0, 1);
  font-size: 17px;
  font-family: PingFang-SC-Bold;
  text-align: right;
}
.van-cell:after {
  border: 0;
}
.van-cell {
  padding: 0;
}
/deep/.van-uploader__upload {
  margin: 0;
}

/deep/.van-uploader__upload {
  background: transparent;
  i {
    font-size: 84px !important;
  }
}

@import "../../assets/styles/reserve.less";
</style>